import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from '../components/SEO';
import { Layout } from '../components/Layout';
import MdxLink from '../components/MdxLink';

const Basic = ({ data }) => {
  const { title } = data.mdx.frontmatter;
  const { body } = data.mdx;
  const { intro_image_absolute, intro_image_hide_on_mobile, intro_image } = data.mdx.frontmatter;
  const introImageClasses = `intro-image ${intro_image_absolute && 'intro-image-absolute'} ${intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <>
      <SEO title={title} />

      <div className="container pb-6 pt-6 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{title}</h1>
	        <div class="content"><MDXRenderer components={{ a: MdxLink }}>{body}</MDXRenderer></div>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
      body
    }
  }
`;

export default Basic;
